<template>
  <b-card>
    <ValidationObserver>
      <form @submit.prevent="saveItem">
        <b-row>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="name">Name:</label>
              <validation-provider
                name="name"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.name"
                  placeholder="City Name"
                />
              </validation-provider>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="isPinned">Pin Location: </label>
              <validation-provider name="isPinned" rules="required">
                <div class="demo-inline-spacing" style="margin-top: 8px">
                  <div class="custom-control custom-radio mt-0">
                    <input
                      name="isPinned"
                      class="custom-control-input"
                      type="radio"
                      id="isPinned_true"
                      value="true"
                      v-model="formPayload.isPinned"
                    />
                    <label class="custom-control-label" for="isPinned_true">
                      Yes
                    </label>
                  </div>
                  <div class="custom-control custom-radio mt-0">
                    <input
                      name="isPinned"
                      class="custom-control-input"
                      type="radio"
                      id="isPinned_false"
                      value="false"
                      v-model="formPayload.isPinned"
                    />
                    <label
                      class="custom-control-label"
                      for="isPinned_false"
                    >
                      No
                    </label>
                  </div>
                </div>
              </validation-provider>
            </div>
          </b-col>
        </b-row>

        <b-col cols="12" class="p-0 mt-2">
          <button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary"
            :disabled="isLoading"
          >
            {{ isLoading ? "Submitting..." : "Submit" }}
          </button>
          &nbsp; &nbsp;
          <a
            @click="handleBackButton()"
            variant="secondary"
            class="btn waves-effect waves-float waves-light btn-secondary"
          >
            Cancel
          </a>
        </b-col>
      </form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BFormFile,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import _ from "lodash";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormFile,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  data() {
    return {
      required,
      isLoading: false,
      formPayload: {
        name: "",
        isPinned: null,
      },
      validations: "",
    };
  },
  methods: {
    handleBackButton() {
      history.back();
    },
    saveItem() {
      this.isLoading = true;
      
      this.$http
        .post(`/admin/location`, this.formPayload)
        .then((response) => {
          this.isLoading = false;
          successNotification(this, "Success", "Lokasi sukses dibuat!");
          this.$router.push({ name: "location" });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta) {
            this.validations = error.response.data.meta.message;
            errorNotification(this, "Error", this.validations.join(", "));
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
.media_file {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
</style>
